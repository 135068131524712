

    .checkout-steps  {
        border-radius: var(--border-radius-small);
    }

    .checkout-sub-step{
        margin-bottom: calc(var(--global-margin) * 1.5);

        .sub-step-success-icon {
            display: none;
        }

        .checkout-sub-step-edit {
            display: none;
            margin-inline-start: auto;
        }

        .sub-step-summary {
            display: none;
        }

        &.complete {
            border-left: 4px solid var(--global-success-background);
           // border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;

            [dir=rtl] & {
               // border-radius:  var(--border-radius-small) 0 0 var(--border-radius-small);
            }
            .sub-step-success-icon {
                display: inline-block;
            }

            .checkout-sub-step-edit {
                display: inline-block;
            }

            .sub-step-summary {
                display: block;
            }

        }
    }

    .payment-iframe-container{
        padding-top: calc(var(--global-margin) * 2);
        padding-bottom: calc(var(--global-margin) * 2);
    }

    .upsales-container {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        height: 100%;
        z-index: 29;
        background: transparent;

        .overlay-background {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            height: 100%;
            background: rgba(0,0,0,0.2);
            z-index: 30;
        }
        .wrapper {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            height: 350px;
            background: var(--global-inverse-color);
            border-top: 3px solid var(--global-primary-background);
            z-index: 31;

        }
    }

    .details-splash {
        position: relative;
        .splash-title {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            min-width: 200px;
            font-size: calc(var(--global-font-size) * 1.3);
            font-weight: 600;
            color: var(--global-secondary-background);
            padding-top: calc(var(--global-margin) * 0.25);
            padding-bottom: calc(var(--global-margin) * 0.25);
            padding-left: calc(var(--global-margin) * 1.5);
            padding-right: calc(var(--global-margin) * 1.5);


        }
        .splash-side {
            padding-top: calc(var(--global-margin) * 2.5);
            padding-bottom: calc(var(--global-margin) * 2.5);
            background-color: var(--global-secondary-background);
            color: var(--global-inverse-color);

            h4 {
                color: var(--global-inverse-color);
            }
            &.splash-side--primary {
                background-color: var(--global-primary-background);
            }

            &.splash-side--opposite {
                text-align: right;

                [dir=rtl] & {
                    text-align: left;
                }
            }

            .button-wrapper {
                .uk-button {
                    min-width: 50%;
                }
            }
        }
    }
